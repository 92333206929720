<template>
  <div>
    <!-- Seu menu aqui -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  // Componente vazio, pois é apenas um contêiner para o menu
};
</script>
